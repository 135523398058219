import API from "./apiCreate";
import { getHeaders } from "./getHeaders";
import { getParams } from "./getParams";

export { API };

export const usersAPI = {
  create: (conf) => API.post("user/create", conf.data, getHeaders()),
  getUsers: ({ params, ...conf }) =>
    API.get(`user/${getParams(conf)}`, getHeaders()),
  getUser: (conf) =>
    API.get(`user/view/${conf.id}${getParams(conf)}`, getHeaders()),
  upd: (conf) => API.put(`user/update/${conf.id}`, conf.data, getHeaders()),
  disable: (conf) => API.put(`user/disable/${conf.id}`, conf.data, getHeaders()),
  restore: (conf) => API.put(`user/restore/${conf.id}`, conf.data, getHeaders()),
  updateBalance: (conf) => API.put(`user/update-balance/${conf.id}`, conf.data, getHeaders()),
  addContract: (conf) =>
    API.post("user/upload-contract", conf.data, getHeaders(["FormData"])),
  removeContract: (conf) =>
    API.delete(`user/delete-contract/${conf.id}`, conf.data, getHeaders()),
  updAvatar: (conf) =>
    API.put(
      `user/update-avatar/${conf.id}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  register: (body) => API({ url: `user/signup`, ...body, method: "POST" }),
  signupVk: (body) => API.post(`auth/signup-vk`, body),
  changePassword: (conf) =>
    API.post(`user/change-password/${conf.id}`, conf.data, getHeaders()),
  restorePassword: (body) => API.post(`auth/restore-password`, body),
  resetPassword: (conf) =>
    API.post(`user/reset-password/${conf.id}`, conf.data, getHeaders()),
  getStudents: ({ params }) =>
    API.get(`user/get-students-by-direction`, params),

  getSeminarian: (conf) =>
    API.get(
      `user/seminarians-by-direction?directionId=${
        conf.direction_id
      }${getParams(conf, false)}`
    ),
  addDirection: ({ data, params }) =>
    API({ url: `user/add-direction`, data, params, method: "post" }),
  changeDirection: ({id, data}) =>
    API.post(`user/change-direction?id=${id}`, data),
  directionBySeminarian: ({ id, params }) =>
    API({
      url: `user/directions-by-seminarian?seminarianId=${id}`,
      params,
      method: "GET",
    }),
  removeDirection: ({ id }) =>
    API({ url: `user/delete-direction?id=${id}`, method: "DELETE" }),
  prevDirectionOrder: (params) => API({
    url:`user/get-prev-direction-order`,
    params,
    method: "GET",
  }),

  studentsRating: (body) => API({ url: `user/students-rating`, ...body }),
  seminarianByDirection : ({id}) => API({ url: `user/get-user-by-direction/?directionId=${id}&expand=seminarian`,method: "GET"   }),
  switchRole: (role) => API.post('user/switch-role', { role }),
  updateRoles: ({id, data}) => API.put(`user/update-roles/${id}`, data, getHeaders()),
  authorization: (id) => API.get(`user/authorization/${id}`),
};

export const profileAPI = {
  login: ({ email, password }) =>
    API.post(
      "auth/login/?expand=roles",
      { password, email },
      { withCredentials: true }
    ),
  // отправить в Redux
  // 2-ой енд поинт
  appLogin: ({ app_id, uuid }) =>
    API.post(
      "auth/login/?expand=roles",
      { type: "app", app_id, uuid },
      { withCredentials: true }
    ),
};

export const staticDataAPI = {
  getRole: () => API.get("user/roles", getHeaders()),
};

export const thematicsAPI = {
  getAll: (body) =>
    API({
      url: `direction-filter`,
      ...body,
      params: { ...body?.params, pageSize: false },
    }),
  add: (conf) => API.post("direction-filter/create", conf.data, getHeaders()),
  upd: (conf) =>
    API.put(`direction-filter/update/${conf.id}`, conf.data, getHeaders()),
  remove: (conf) =>
    API.delete(`direction-filter/delete/${conf.id}`, getHeaders()),
  view: (conf) => API.get(`direction-filter/view/${conf.id}`, getHeaders()),
  sorting: (conf) => API.post(`direction-filter/change-sorting`, conf.data),
};

export const directionAPI = {
  getAll: (conf) => API.get(`direction/${getParams(conf)}`, {}, getHeaders()),
  get: (conf) =>
    API.get(`direction/view/${conf.id}${getParams(conf)}`, getHeaders()),
  getTariff: (conf) =>
    API.get(`direction-tariff/view/${conf.id}`, getHeaders()),
  getHWbyMyCourse: (group_id,children_id) => API.get(`direction-plan/group?group_id=${group_id}&children_id=${children_id}&expand=group.direction,group.coursePlans,group.materialPlans,group.lessonPlans.lesson.lessonTasks.task,group.teacher,group.lessonPlans.lesson.lessonVideos,group.lessonPlans.lesson.lessonMaterials,children.homeWork,group.materialPlans.children.homeWork`, {}, getHeaders()),
  add: (conf) => API.post("direction/create", conf.data, getHeaders()),
  upd: (conf) =>
    API.post(`direction/update/${conf.id}`, conf.data, getHeaders()),
  remove: (conf) => API.delete(`direction/delete/${conf.id}`, getHeaders()),
  sorting: (conf) => API.post(`direction/change-sorting`, conf.data),
  getTeacherByDiraction: (conf) =>
    API.get(
      `direction/${getParams(conf)}&expand=directionSeminarians.seminarian`,
      {},
      getHeaders()
    ),
  getTypes: () => API.get('/direction-tariff/types')
};

export const courseAPI = {
  getAll: (conf) =>
    API.get(`direction-course/${getParams(conf)}`, getHeaders()),
  add: (data) => API.post("direction-course/create", data, getHeaders()),
  upd: (conf) =>
    API.put(`direction-course/update/${conf.id}`, conf.data, getHeaders()),
  remove: (id) => API.delete(`direction-course/delete/${id}`, getHeaders()),
  view: (conf) => API.get(`direction-course/view/${conf.id}${getParams(conf)}`),
  sorting: (conf) => API.post(`direction-course/change-sorting`, conf.data),
  getSchedule: (groupId, courseId) => API.get(`direction-course/get-schedule?groupId=${groupId}&courseId=${courseId}`),
  uploadCourse: (data)=> API.post(`direction-course/upload`, data)
};

export const themeAPI = {
  getAll: (conf) => API.get(`task-topic/${getParams(conf)}`, getHeaders()),
  get: (id) => API.get(`task-topic/${id}`, getHeaders()),
  add: (conf) => API.post("task-topic/create", conf.data, getHeaders()),
  upd: (conf) =>
    API.put(`task-topic/update/${conf.id}`, conf.data, getHeaders()),
  remove: (id) => API.delete(`task-topic/delete/${id}`, getHeaders()),
};

export const subThemeAPI = {
  getAll: (conf) => API.get(`task-subtopic/${getParams(conf)}`, getHeaders()),
  get: (id) => API.get(`task-subtopic/${id}`, getHeaders()),
  add: (data) => API.post("task-subtopic/create", data, getHeaders()),
  upd: (conf) =>
    API.put(`task-subtopic/update/${conf.id}`, conf.data, getHeaders()),
  remove: (id) => API.delete(`task-subtopic/delete/${id}`, getHeaders()),
};

export const examNumberAPI = {
  getAll: (conf) =>
    API.get(`task-exam-number/${getParams(conf)}`, getHeaders()),
  get: (id) => API.get(`task-exam-number/${id}`, getHeaders()),
  add: (conf) => API.post("task-exam-number/create", conf.data, getHeaders()),
  upd: (conf) =>
    API.put(`task-exam-number/update/${conf.id}`, conf.data, getHeaders()),
  remove: (id) => API.delete(`task-exam-number/delete/${id}`, getHeaders()),
};

export const homeTaskApi = {
  getTypes: () => API.get("home-task/task-types", getHeaders()),
  getAll: (conf) => API.get(`home-task/${getParams(conf)}`, getHeaders()),
  get: (id) =>
    API.get(`home-task/view/${id}?expand=homeTaskFiles`, getHeaders()),
  add: (conf) =>
    API.post(
      `home-task/create/${getParams(conf)}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  upd: (conf) =>
    API.put(
      `home-task/update/${conf.id}${getParams(conf)}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  remove: (id) => API.delete(`home-task/delete/${id}`, getHeaders()),
  removeFile: (id) =>
    API.delete(`home-task/delete-file-task/${id}`, getHeaders()),
};

export const courseMaterialApi = {
  getAll: (conf) => API.get(`course-material/${getParams(conf)}`, getHeaders()),
  add: (data) => API.post("course-material/create", data, getHeaders()),
  upd: (conf) =>
    API.put(`course-material/update/${conf.id}`, conf.data, getHeaders()),
  view: (conf) =>
    API.get(`course-material/view/${conf.id}${getParams(conf)}`, getHeaders()),
  remove: (id) => API.delete(`course-material/delete/${id}`, getHeaders()),
  updProbe: (conf) =>
    API.put(`course-material/update-probe/${conf.id}`, conf.data, getHeaders()),
  updControl: (conf) =>
    API.put(
      `course-material/update-control/${conf.id}`,
      conf.data,
      getHeaders()
    ),
  updColloquium: (conf) =>
    API.put(
      `course-material/update-colloquium/${conf.id}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  updColloquiumGallery: (conf) =>
    API.put(
      `course-material/update-colloquium-gallery/${conf.id}`,
      conf.data,
      getHeaders()
    ),
  removeColloquiumGallery: (id) =>
    API.delete(`course-material/delete-colloquium-gallery/${id}`, getHeaders()),

  sorting: (conf) => API.post(`course-material/change-sorting`, conf.data),
};

export const lessonThemeApi = {
  getAll: (conf) => API.get(`topic-lesson/${getParams(conf)}`, getHeaders()),
  add: (data) => API.post("topic-lesson/create", data, getHeaders()),
  upd: (conf, config = {}) =>
    API.put(`topic-lesson/update/${conf.id}`, conf.data, {
      ...config,
      headers: getHeaders().headers
    }),
  view: (conf) =>
    API.get(`topic-lesson/view/${conf.id}${getParams(conf)}`, getHeaders()),
  remove: (id) => API.delete(`topic-lesson/delete/${id}`, getHeaders()),
  setRating: (body) =>
    API({ url: "topic-lesson/set-video-rating", ...body, method: "POST" }),
  getLessonData: (params) => API.get(`topic-lesson/get-lesson-data`, {params}),
  sorting: (conf) => API.post(`topic-lesson/change-sorting`, conf.data),
};

export const childrenAPI = {
  add: (data) => API.post("user/create-children", data, getHeaders()),
};

export const additionalProbeApi = {
  getAll: (conf) =>
    API.get(`additional-probe${"/" + getParams(conf)}`, getHeaders()),
  add: (conf) =>
    API.post(
      `additional-probe/create/${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  upd: (conf) =>
    API.put(
      `additional-probe/update/${conf.id}${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  view: (conf) =>
    API.get(`additional-probe/view/${conf.id}${getParams(conf)}`, getHeaders()),
  remove: (conf) =>
    API.delete(`additional-probe/delete/${conf.id}`, getHeaders()),
};

//Groups
export const directionGroupApi = {
  get: (conf) => API.get(`direction-group${getParams(conf)}`, getHeaders()),
  add: (conf) =>
    API.post(
      `direction-group/create/${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  addStudents: (conf) =>
    API.post(
      `direction-group/distribute ${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  updGroup: (conf) =>
    API.post(
      `direction-group/change-group${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  updSeminarian: (conf) =>
    API.post(
      `direction-group/change-teacher${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  updGroupName: (conf) =>
    API.post(
      `direction-group/change-name-group${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),

  updGroupTeacher: (conf) => 
    API.put(
      `direction-group/update/${conf.id}`,
      { teacher_id: conf.teacher_id },
      getHeaders()
    ),
  
  removeGroup: (conf) =>
    API.delete(`direction-group/delete/${conf.id.value}`, getHeaders()),
    
  getStudentsBygroup: (id) => API.get(`/direction-group/get-students?group_id=${id}`),
};

export const directionOrdersApi = {
  get: (conf) =>
    API.get(
      `direction-orders${getParams(conf)}${
        conf.notFree ? "&notFree=true" : ""
      }${
        conf.allRows ? "&allRows=true" : ""
      }`,
      getHeaders()
    ),
  show: (id) => API.get(`direction-orders/show/${id}`),
  view: (conf) => API.get(`direction-orders/view?id=${conf.id}`),
  add: (conf) =>
    API.post(
      `direction-orders/create${getParams(conf)}`,
      conf.data,
      getHeaders()
    ),
  remove: (conf) =>
    API.delete(`direction-orders/delete/${conf.id}`, getHeaders()),
  updComments: (conf) =>
    API.put(
      `direction-orders/update-comment?id=${conf.id}`,
      conf.data,
      getHeaders()
    ),
  createOrder: (conf) =>
    API.post(
      `direction-orders/create-order${getParams(conf)}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  getMyOrders: (body) =>
    API({ url: `direction-orders/get-student-directions`, ...body }),

  buy: (conf) =>
    API.post(`direction-orders/buy-direction${getParams(conf)}`, conf.data),
  payment: (conf) => API.post(`direction-orders/payment`, conf.data),
  extend: (body) =>
    API({ url: `direction-orders/extend`, ...body, method: "POST" }),
  extendByManager: (body) =>
    API({ url: `direction-orders/extend-by-manager`, ...body, method: "POST" }),
  extendClass: (body) => API({url:`direction-orders/extend-class`, ...body, method: "POST"}),
  extendClassByManager: (body) => API({url:`direction-orders/extend-class-by-manager`, ...body, method: "POST"}),
  activePromoCode: (conf) =>
    API.post(
      `direction-orders/active-promo-code?code=${conf.promo_code}`,
      conf.data
    ),

  updPrivateInfo: ({ data, params }) =>
    API({
      url: `direction-orders/update-private-info`,
      data,
      params,
      method: "PUT",
    }),

  index: (body) =>
    API({ url: `direction-orders/index`, ...body, method: "GET" }),
  dataFilled: (body) =>
    API({ url: `direction-orders/data-filled`, ...body, method: "PUT" }),
  sendToBot: (body) =>
    API({ url: `direction-orders/send-to-bot`, ...body, method: "PUT" }),
  parentTouch: (body) =>
    API({ url: `direction-orders/parent-touch`, ...body, method: "PUT" }),

  managerPayments: (body) =>
    API({ url: `direction-orders/manager-payments`, ...body, method: "GET" }),
  orderDisable: (body) =>
    API({ url: `direction-orders/order-disable`, ...body, method: "PUT" }),
  postpone: (body) =>
    API({ url: `direction-orders/postpone`, ...body, method: "POST" }),
  postponeCancel: (body) =>
    API({ url: `direction-orders/postpone-cancel`, ...body, method: "POST" }),
  
  getPrivateOwners: (teacher_id, direction_id) => API.get(`direction-orders/private-students-by-seminarian?teacher_id=${teacher_id}&direction_id=${direction_id}`),
};

export const childrenGroupApi = {
  getGroups: (conf) =>
    API.get(
      `direction-plan/group?group_id=${conf.id}&children_id=${conf.children}&noEmptyMaterialPlans=true&expand=group.direction,group.coursePlans.order.tariff,group.materialPlans,group.lessonPlans.lesson.lessonTasks.task,group.teacher,group.lessonPlans.lesson.lessonVideos,group.lessonPlans.lesson.lessonMaterials`
    ),
  getIndividual: (conf) =>
    API.get(
      `direction-plan/private?directionId=${conf.id}&noEmptyMaterialPlans=true&expand=coursePlans,materialPlans,lessonPlans.lesson.lessonTasks.task,order.direction,order.teacher,lessonPlans.lesson.lessonVideos`
    ),
};

export const homeWorkApi = {
  get: (conf) => API.get(`home-work${getParams(conf)}&id=${conf.id}`),
  view: (conf) => API.get(`home-work/view/${conf.id}${getParams(conf)}`),
  sendAnswer: (conf) =>
    API.post(
      `home-work/send-answer/${conf.id}${getParams(conf)}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  add: (conf) => API.post(`home-work/set-home-work`, conf.data),
  addLink: (conf) => API.post(`home-work/type-link`, conf.data),
  pass: (conf) => API.post(`home-work/pass-work?id=${conf.id}`, conf.data),
  evaluation: (conf) =>
    API.post(`home-work/work-evaluation?id=${conf.id}`, conf.data),
  getOwnWorks: (conf) => API.get(`home-work/get-own-works/${getParams(conf)}`),
  addCommentFile: (conf) =>
    API.post(
      `home-work/add-comment-files`,
      conf.data,
      getHeaders(["FormData"])
    ),
  removeCommentFile: (conf) =>
    API.delete(
      `home-work/delete-comment-file?work_id=${conf.work_id}&task_id=${conf.task_id}&file_index=${conf.index}`
    ),
  timeout: (conf) =>
    API.post(`home-work/work-evaluation?id=${conf.id}&timeout=true`, conf.data),
  setUnique: (conf) => API.post(`home-work/set-unique-home-work`, conf.data),
  remove: (conf) => API.delete(`home-work/canceled-work?id=${conf.id}`),
  archive: (id) => API.post(`home-work/archive?id=${id}`),
  unarchive: (id) => API.post(`home-work/unarchive?id=${id}`),
};

export const probeApi = {
  get: (conf) =>
    API.get(
      conf.id
        ? `probe/${getParams(conf)}&planId=${conf.id}`
        : `probe/${getParams(conf)}`
    ),
  view: (conf) => API.get(`probe/view${getParams(conf)}&id=${conf.id}`),
  start: (conf) => API.post(`probe/start-probe?workId=${conf.id}`),
  pass: (conf) =>
    API.post(
      `probe/pass-work?workId=${conf.id}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  getOwnWorks: (conf) => API.get(`probe/get-own-works${getParams(conf)}`),
  evaluation: (conf) =>
    API.post(`probe/work-evaluation?id=${conf.id}`, conf.data),
  getMy: (conf) => API.get(`probe/get-my-probes${getParams(conf)}`),
  addCommentFile: (conf) =>
    API.post(`probe/add-comment-files`, conf.data, getHeaders(["FormData"])),
  removeCommentFile: (conf) =>
    API.delete(
      `probe/delete-comment-file?work_id=${conf.work_id}&task_id=${conf.task_id}&file_index=${conf.index}`
    ),
  timeout: (conf) =>
    API.post(`probe/work-evaluation?id=${conf.id}&timeout=true`, conf.data),
};

export const controlApi = {
  get: (conf) => API.get(`control-work${getParams(conf)}`),
  view: (conf) => API.get(`control-work/view${getParams(conf)}&id=${conf.id}`),
  start: (conf) => API.post(`/control-work/start-work?id=${conf.id}`),
  pause: (conf) => API.post(`control-work/stop-work?id=${conf.id}`),
  sendAnswer: (conf) =>
    API.post(
      `control-work/send-answer?id=${conf.id}`,
      conf.data,
      getHeaders(["FormData"])
    ),
  pass: (conf) => API.post(`control-work/pass-work?id=${conf.id}`),
  evaluation: (conf) =>
    API.post(`control-work/work-evaluation?id=${conf.id}`, conf.data),

  addCommentFile: (conf) =>
    API.post(
      `control-work/add-comment-files`,
      conf.data,
      getHeaders(["FormData"])
    ),
  removeCommentFile: (conf) =>
    API.delete(
      `control-work/delete-comment-file?work_id=${conf.work_id}&task_id=${conf.task_id}&file_index=${conf.index}`
    ),

  timeout: (conf) =>
    API.post(
      `control-work/work-evaluation?id=${conf.id}&timeout=true`,
      conf.data
    ),
};

export const recordingLessonAPI = {
  getAll: (conf) => API.get(`recording-lesson${getParams(conf)}`),
  add: (conf) =>
    API.post(`recording-lesson/create${getParams(conf)}`, conf.data),
  upd: (conf) =>
    API.put(`recording-lesson/update/${conf.id}${getParams(conf)}`, conf.data),
  view: (conf) => API.get(`recording-lesson/view/${conf.id}${getParams(conf)}`),
  remove: (conf) => API.delete(`recording-lesson/delete?id=${conf.id}`),
};

export const timetableAPI = {
  getFamiliarLessonIds: _ => API.get(`timetable/get-familiar-lesson-ids`),
  get: ({ params }) => API({ url: `timetable`, params, method: "GET" }),
  getChildrenAll: ({ params }) => API({ url: `timetable/children-all`, params, method: "GET" }),
  getChildren: ({ params }) => API({ url: `timetable/children-own`, params, method: "GET" }),
  show: (id, date = null) => API.get(`timetable/show?id=${id}&date=${date}`),
  add: (conf) => API.post(`timetable/create?conference=${conf.type}`, conf.data),
  done: (conf) => API.post(`timetable/done?id=${conf.id}`, conf.data),
  changeDiscount: (conf) =>
    API.post(`timetable/change-discount?id=${conf.id}&date=${conf.date}`, conf.data),
  reschedule: (conf) =>
    API.post(`timetable/reschedule?id=${conf.id}`, conf.data),
  changeSpeaker: (conf) =>
    API.post(`timetable/change-speaker?id=${conf.id}`, conf.data),
  conference: (body) =>
    API({ url: `timetable/conference-without-status`, ...body, method: "GET" }),
  cancel: (conf) => API.post(`timetable/cancel?id=${conf.id}`, conf.data),

  cancelReport: (body) =>
    API({ url: `timetable/cancel-report`, ...body, method: "POST" }),

  massReport: (data) =>
    API({ url: `timetable/mass-report`, data, method: "POST" }),

  pupilsClass: (conf) =>
    API.post(`timetable/pupils-class?id=${conf.id}`, conf.data),
    
  seminariansСlasses: (conf) =>
    API.post(`timetable/seminarians-classes?id=${conf.id}`, conf.data),
  
    selectTopic: (conf) =>
    API.post(`timetable/select-topic?id=${conf.id}`, conf.data),

    recordingViaSchedule: (data) =>
    API.post(`timetable/enroll-via-schedule`, data),
    
    cancelRecording: (data) =>
    API.post(`timetable/cancel-enrollment`, data),
};

export const lessonAbstractAPI = {
  get: (conf) => API.get(`lesson-abstract${getParams(conf)}`),
  add: (conf) =>
    API.post(`lesson-abstract/create`, conf.data, getHeaders(["FormData"])),
  evaluation: (conf) =>
    API.post(`lesson-abstract/evaluation?id=${conf.id}`, conf.data),
  existAbstract: (conf) =>
    API.get(`lesson-abstract/exist-abstract?plan_id=${conf.id}`),
};

export const journalApi = {
  getHomeWork: ({ params }) =>
    API({ url: `student-journal/home-works`, params, method: "GET" }),

  getProbe: ({ params }) =>
    API({ url: `student-journal/probe-works`, params, method: "GET" }),

  getControlWork: ({ params }) =>
    API({ url: `student-journal/control-works`, params, method: "GET" }),

  getAttendance: ({ params }) =>
    API({ url: `student-journal/attendance`, params, method: "GET" }),
  addAttendance: (conf) =>
    API.post(`student-journal/add-attendance`, conf.data),
  editGrades: (conf) => API.put('student-journal/edit-grades', conf.data),
  periodCreate: (data) => API.post(`/journal-periods/create-period`, data),
  periodUpdate: (id, data) => API.put(`/journal-periods/update/${id}`, data),
  periodGet: () => API.get(`/journal-periods/list`),
  periodDelete: (id) => API.delete(`/journal-periods/delete/${id}`),
  updateButtons: (data) => API.put('student-journal/update-buttons', data),
  getButtons: () => API.put('student-journal/buttons'),
  periodGrage: (id) => API.delete(`/student-journal/delete-grade?gradeId=${id}`),

  getColumnsSettings: () => API.get(`student-journal/get-columns`),
  addColumnsSettings: (data) => API.post(`student-journal/add-columns`, data),
  // updateColumnsSettings: (data) => API.put(`student-journal/change-columns`, data),
  getAdditional: (id) => API.get(`/student-journal/show-additional?group_id=${id}`),
  fillAdditional: (data) => API.post(`student-journal/fill-additional`, data),
};

export const seminarPresentationAPI = {
  get: (conf) => API.get(`seminar-presentation${getParams(conf)}`),
  getType: (body) => API.get(`seminar-presentation/get-seminar-types`),
  add: (conf) => API.post(`seminar-presentation/create`, conf.data),
  upd: (conf) =>
    API.put(`seminar-presentation/update?id=${conf.id}`, conf.data),
  remove: (conf) => API.delete(`seminar-presentation/delete?id=${conf.id}`),
};

export const reportAPI = {
  reportForParents: {
    get: (body) =>
      API({ url: `reports/report-for-parents`, ...body, method: "GET" }),
    add: (body) =>
      API({ url: `reports/create-report-parent`, ...body, method: "POST" }),
    transferred: (conf) =>
      API.post(`reports/transferred-report-parent?id=${conf.id}`),
    getChildren: ({ params }) =>
      API({ url: `reports/child-for-parent-report`, params, method: "GET" }),
  },

  private: {
    week: ({ params }) =>
      API({ url: `reports/private-week`, params, method: "GET" }),
    seminarian: ({ params }) =>
      API({ url: `reports/private-seminarian`, params, method: "GET" }),
    seminarianToExcel: ({ params }) =>
      API({ url: `reports/private-seminarian-to-excel`, params, method: "GET" }),
    students: ({ params }) =>
      API({ url: `reports/private-students`, params, method: "GET" }),
  },

  lectureAnalytics: ({ params }) =>
    API({ url: `reports/lecture-analytics`, params, method: "GET" }),

  group: {
    group: (body) => API({ url: `reports/groups`, ...body, method: "GET" }),
    wrapper: ({params}) => API({url: 'reports/group-reports', params, method: 'GET'}),
    overview: ({params}) => API({url: `reports/groups-overview`, params, method: "GET"}),
    seminarian: ({ params }) =>
        API({ url: `reports/group-seminarian`, params, method: "GET" }),
  },

  payments: (body) => API({ url: `reports/payments`, ...body }),

  ownBacklog: (body) => API({ url: "reports/own-backlog", ...body }),

  sop: {
    get: (body) => API({ url: "reports/sop-questions", ...body }),
    send: (body) => API({ url: "reports/sop-send", ...body, method: "POST" }),
  },

  platformVisits: (params) =>
    API({ url: `reports/platform-visits`, params, method: "GET" }),

  platformVisitsUpdate: () => 
    API({ url: "/reports/platform-visits-refresh", method: "POST" }),
};

export const promoCodeAPI = {
  get: (body) => API({ url: `promo-code`, ...body }),
  add: (conf) => API.post(`promo-code/create`, conf.data),
  remove: (conf) => API.delete(`promo-code/delete?id=${conf.id}`),
};

export const supportAPI = {
  get: (body) => API({ url: `support/index`, ...body }),
  getTypes: (body) => API({ url: `support/get-support-types`, ...body }),
  add: (body) =>
    API({
      url: `support/create`,
      ...body,
      method: "POST",
      headers: { "Content-type": "multipart/form-data" },
    }),
  done: (body) => API({ url: `support/done`, ...body, method: "POST" }),
  accepted: (body) => API({ url: `support/accepted`, ...body, method: "POST" }),
  cancel: (body) => API({ url: `support/cancel`, ...body, method: "POST" }),
  remove: (body) => API({ url: `support/delete`, ...body, method: "DELETE" }),
  unreadCount: () => API.get('support/unread-count'),
  read: (supportId) => API.post('support/read', { supportId }),
};

export const bannerApi = {
  index: (body) => API({ url: `banner/index`, ...body, method: "GET" }),
  show: (id) => API.get(`banner/show?id=${id}`),
  create: (data) => API.post(`banner/create`, data, {
    headers: { "Content-type": "multipart/form-data" },
  }),
  update: (data) => API.post(`banner/update`, data, {
    headers: { "Content-type": "multipart/form-data" },
  }),
  remove: (id) => API.post(`banner/delete?id=${id}`),
  copy: (data) => API.post(`banner/copy`, data),
  sort: (data) => API.post(`banner/sort`, data),
};

export const excelAPI = {
  getExcelTable: (body) => API({url: 'user/export', ...body, method: 'GET'}),
}

export const communicationApi = {
  index: (params) => API.get('communication', { params }),
  update: (id, params) => API.put(`communication/update/${id}`, params),
  count: () => API.get('communication/count'),
};

export const settingApi = {
  getAll: () => API.get('setting/get-all'),
  ensureReports: () => API.get('setting/ensure-reports'),
  update: (params) => API.put('setting/update-setting', params),
  uploadLogo: (data) => API.post('setting/upload-logo', data),
  uploadFavicon: (data) => API.post('setting/upload-favicon', data),
  switchHundredSystem: (data) => API.post('setting/switch-hundred-point-system', data),
};

export const menuApi = {
    getMenu: (language, menuType) => API.get(`menu/get-active-menu?language=${language}&menuType=${menuType}`),
    getByRoleName: (roleName, language, menuType) => API.get(`menu/get-menu?roleName=${roleName}&language=${language}&menuType=${menuType}`),
    changeActive: (id, roleName, menuType) => API.put(`menu/change-active?id=${id}&roleName=${roleName}&menuType=${menuType}`),
    changeMenuItemName: (id, name, language, menuType) => API.put(`menu/change-menu-item-name?id=${id}&name=${name}&language=${language}&menuType=${menuType}`),
    changeRank: (id, roleName, isUp, menuType) => API.put(`menu/change-rank?id=${id}&roleName=${roleName}&isUp=${isUp}&menuType=${menuType}`),
};

export const menuReportApi = {
    getMenu: (roleName) => API.get(`menu-report/get-active-menu?roleName=${roleName}`),
    getByRoleName: (roleName, language) => API.get(`menu-report/get-menu?roleName=${roleName}&language=${language}`),
    changeActive: (id, roleName) => API.put(`menu-report/change-active?id=${id}&roleName=${roleName}`),
    changeMenuItemName: (id, name, language) => API.put(`menu-report/change-menu-item-name?id=${id}&name=${name}&language=${language}`),
    changeRank: (id, roleName, isUp) => API.put(`menu-report/change-rank?id=${id}&roleName=${roleName}&isUp=${isUp}`),
};

export const publicUrl = [
    'setting/get-all',
]

export const faqApi = {
  get: (params) => API.get(`faq/get`, {params}),
  create: (params) => API.post('faq/create', params),
  update: (id, params) => API.put(`faq/update/${id}`, params),
  delete: (id) => API.delete(`faq/delete/${id}`),
}

export const adminPasswordsApi = {
  get: (params) => API.get('admin-passwords/get-password-by-key', {params})
}
export const reactionsToTheVideo = {
  get: (params) => API.get('/topic-lesson/own-lesson-video-rating', {params})
}

export const seminarianUncheckedHomework = {
  get: (params) => API.get('/reports/seminarian-unchecked-homework-count', {params})
}

export const fcmApi = {
  storeToken: (token) => API.post('fcm-messaging/store-token', { token }),
  removeToken: (token) => API.post('fcm-messaging/remove-token', { token })
}

export const chatApi = {
  getUserList: (params) => API.get(`chat/user-list`, {params}),
  createChat: (params) => API.post(`chat/create-chat`, params),
  getChatList: (params) => API.get(`chat/chat-list`, {params}),
  getChat: (id) => API.get(`chat/chat-messages/${id}`, ),
  getActiveUsers: () => API.get(`chat/get-active-users`),
  createMessage: (params, config) => API.post(`chat/create-message`, params, config),
  addMember: (params) => API.post(`chat/add-member`, params),
  deleteMember: (id) => API.delete(`chat/remove-member/${id}`),
  readAllChatMessages: (chatId) => API.put(`chat/read-messages/${chatId}`, ),
  fixMessage: (id) => API.put(`chat/fix-message/${id}`),
  updateChatTitle: (id,params) => API.put(`chat/update-chat/${id}`, params),
  updateChatImage: (id,params,config = {}) => API.post(`chat/update-chat-avatar/${id}`, params, {
    ...config,
    headers: getHeaders().headers
  }),
  removeChat: (id) => API.delete(`chat/remove-chat/${id}`),
  removeMessage: (id) => API.delete(`chat/remove-message/${id}`),
  fixChat: (id) => API.put(`chat/fix-chat/${id}`),
  updateRoleMember: (id, params) => API.put(`chat/update-member/${id}`, params),
  addMessageFile: (id, params, config) => API.post(`chat/add-message-file/${id}`, params, config),
  unFixMessage: (chatId) => API.put(`chat/unfix-message/${chatId}`),
  unFixChat: () => API.put(`chat/unfix-chat`),
  mute: (id) => API.post(`chat/mute?id=${id}`),
  unmute: (id) => API.post(`chat/unmute?id=${id}`),
}

export const classApi = {
  index: () => API.get('classes'),
  getClassesById: (folderId) => API.get(`classes?folder_id=${folderId}`),
  show: (id) => API.get(`classes/show/${id}`),
  showByUser: (userId) => API.get(`classes/show-by-user?userId=${userId}`),
  showForPayment: () => API.get(`classes/show-for-payment`),
  create: (params) => API.post(`classes/create`, params),
  update: (id, params) => API.post(`classes/update/${id}`, params),
  delete: (id) => API.delete(`classes/delete/${id}`),
  attachChildren: (id, params) => API.post(`classes/attach-children/${id}`, params),
  detachChildren: (id, params) => API.post(`classes/detach-children/${id}`, params),
  attachUserClassGroup: (id, params) => API.post(`classes/attach-user-class-group/${id}`, params),
  detachUserClassGroup: (id, params) => API.post(`classes/detach-user-class-group/${id}`, params),
  changePriceForChild: (params) => API.post('classes/change-price-for-child', params),
  changeDiscountForChild: (params) => API.post('classes/change-discount-for-child', params),

  folders: () => API.get('/class-folder'),
  addFolder: (params) => API.post('/class-folder/add-new', params),
  editFolder: (idFolder, params) => API.put(`/class-folder/rename/${idFolder}`, params),
  deleteFolder: (id) => API.delete(`/class-folder/delete/${id}`),
  sorting: (conf) => API.post(`/classes-sorting`, conf.data),
}

export const languagesApi = {
  save: (params) => API.post(`languages/save-settings`, params),
}

export const news = {
  get: (id) => API.get(`news/view/${id}`),
  getAll: () => API.get(`news`),
  create: () => API.post(`news/draft-or-new`),
  delete: (id) => API.post(`news/delete/${id}`),
  save: (id, param) => API.post(`news/save/${id}`, param),
  createBlock: (newsId) => API.post(`news/new-block`, newsId),
  updateBlock: (id, param) => API.post(`news/update-block/${id}`, param),
  deleteBlock: (id) => API.delete(`news/delete-block/${id}`),
  createComment: (id, param) => API.post(`news/create-comment/${id}`, param),
  getComment: (id, first, last) => API.get(`news/comments/${id}?first=${first}&last=${last}`),
  toggleLike: (id) => API.post(`news/toggle-like/${id}`),
  deleteСomment: (id) => API.post(`news/delete-comment/${id}`),  
  changeRank: (params) => API.post(`news/change-rank`, params),
}


export const departments = {
  getAll: () => API.get(`/org-units`),
  getAllIndex: () => API.get(`/org-units/select-index`),
 
  departmentUpdate: (id, params) => API(
    {
      url: `org-units/update/${id}`,
      params,
      method: "PUT",
    }
  ),

  departmentUpdateAll: (params) => API.post(`/org-units/update-order`, params),

  create: (params) => API.post(`/org-units/create`, params),
  update: (id, params) => API.post(`org-units/update/${id}`, params),
  delete: (id) => API.delete(`org-units/delete/${id}`),
  addAnEmployeeToDepartment: (params) => API.post(`/org-units/add-user`, params),

  utUsers: (id) => API.get(`org-units/users-list/${id}`),
  deleteEmployee: (params) => API({ url: `org-units/remove-user/`, params, method: "DELETE" }),
  
  userScriptorAdeptoDepartments: (id) => API.get(`org-units/list-by-user/${id}`),
  
}


export const library = {
  createSection: (params) => API.post(`confluence/new-header`, params),
  editSection: (id, data) => API(
    {
      url: `confluence/save-header/${id}`,
      data,
      method: "PUT",
    }
  ),
  editPage: (id, data) => API(
    {
      url: `/confluence/save-page/${id}`,
      data,
      method: "PUT",
    }
  ),
  editBlock: (id, data) => API.post(`/confluence/update-block/${id}`, data),
  createBlock: (params) => API.post(`/confluence/new-block`, params),
  detAll: () => API.get(`confluence`),
  createPage: (params) => API.post(`confluence/draft-or-new`, params),
  getPage: (id) => API.get(`/confluence/view-page/${id}`),
  deleteBlock: (id) => API.delete(`/confluence/delete-block/${id}`),
  deleteSection: (id) => API.delete(`/confluence/delete-header/${id}`),
  deletePage: (id) => API.delete(`/confluence/delete-page/${id}`),
  changeRank: (data) => API(
    {
      url: `/confluence/change-rank`,
      data,
      method: "PUT",
    }
  ),
  changeRankSections: (data) => API(
    {
      url: `/confluence/change-rank-header`,
      data,
      method: "PUT",
    }
  ),
  changeRankPage: (data) => API(
    {
      url: `/confluence/change-rank-page`,
      data,
      method: "PUT",
    }
  ),
  createDepartmentInPage: (params) => API.post(`/confluence/add-org-unit`, params),
  getPageUnits: (id) => API.get(`/confluence/page-units?pageId=${id}`),
  deleteDepartmentInPage: (params) => API.post(`/confluence/remove-org-unit`, params),
}
export const lessonСompleted = {
  completed: (id, params) => API.post(`topic-lesson/lesson-completed/${id}`, params),
}

export const rolesOrder = {
  getList: () => API.get(`auth/list-roles-order`),
  listUpdate: (params) => API.post(`auth/update-roles-order`, params),
  nameUpdate: (params) => API.put(`auth/update-role-name`, params),
}

export const schoolAndUniversitySettings = {
  getAll: () => API.get(`direction/school-and-university`),
  update: (params) => API.post(`direction/update-school-and-university`, params),
}

export const workingHours = {
  delete: (id) => API.delete(`work-hours/delete?id=${id}`),
  create: (params) => API.post(`work-hours/create`, params),
}

export const replenishmentSingleBalance = {
  replenish: (params) => API.post(`polymorph-payment/balance-deposit`, params),
  view: (conf) => API.get(`polymorph-payment/check-payment?id=${conf.id}`),
  updateLink: (conf) => API.get(`polymorph-payment/update-link?id=${conf.id}`),
}